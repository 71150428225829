import {IBLControlImage, TBLElements} from '../components/forms/BLForms/formInterfaces';
import {IInhaltsverzeichnis} from "../components/moleculs/Inhaltsverzeichnis/Inhaltsverzeichnis";

export const ImageHersteller: IBLControlImage = {
    id: 'image_hersteller',
    type: 'image',
    label: 'Logo Hersteller',
    imageType: 'image_editable',
    value: null,
};

export const Seitentitel: IInhaltsverzeichnis = {
    id: 'page_title',
    type: 'inhaltsverzeichnis',
    label: 'Seitentitel',
    placeholder: 'Seitentitel eintragen ...',
    value: "",
    targetId: 'inhaltsverzeichnis',
    targetPage: 3
};

export const EinProduktEinBildTabelleText: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_EinProduktEinBildTabelleText.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkt',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 11,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const EinProduktZweiBilderTabelleText: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_EinProduktZweiBilderTabelleText.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkt',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 11,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const ZweiProdukteTabelleText: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_ZweiProdukteTabelleText.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkt #1',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 8,
                            },
                        ],
                    },
                    {
                        label: 'Produkt #2',
                        fields: [
                            {
                                id: 'product_2',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 8,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const DreiProdukteTabelleText: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_DreiProdukteTabelleText.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkt #1',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: 'Produkt #2',
                        fields: [
                            {
                                id: 'product_2',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: 'Produkt #3',
                        fields: [
                            {
                                id: 'product_3',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const VierProdukteTabelleText: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_VierProdukteTabelleText.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkt #1',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: 'Produkt #2',
                        fields: [
                            {
                                id: 'product_2',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: 'Produkt #3',
                        fields: [
                            {
                                id: 'product_3',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: 'Produkt #4',
                        fields: [
                            {
                                id: 'product_4',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const FuenfProdukteTabelleText: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_FuenfProdukteTabelleText.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: '#1',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#2',
                        fields: [
                            {
                                id: 'product_2',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#3',
                        fields: [
                            {
                                id: 'product_3',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#4',
                        fields: [
                            {
                                id: 'product_4',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#5',
                        fields: [
                            {
                                id: 'product_5',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const VierProdukteTabelle: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_VierProdukteTabelle.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: '#1',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#2',
                        fields: [
                            {
                                id: 'product_2',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#3',
                        fields: [
                            {
                                id: 'product_3',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                    {
                        label: '#4',
                        fields: [
                            {
                                id: 'product_4',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 2,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const SechsProdukteTabelle: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_SechsProdukteTabelle.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: '#1',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: '#2',
                        fields: [
                            {
                                id: 'product_2',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: '#3',
                        fields: [
                            {
                                id: 'product_3',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: '#4',
                        fields: [
                            {
                                id: 'product_4',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: '#5',
                        fields: [
                            {
                                id: 'product_5',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                    {
                        label: '#6',
                        fields: [
                            {
                                id: 'product_4',
                                type: 'pim',
                                extraColumns: 2,
                                recommendedCount: 3,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const VierzigProdukteInTabelle: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_VierzigProdukteInTabelle.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkte',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 4,
                                recommendedCount: 40,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const ZwanzigProdukteBilderInTabelle: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_ZwanzigProdukteBilderInTabelle.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    {
                        label: 'Daten',
                        fields: [
                            Seitentitel,
                            ImageHersteller,
                        ],
                    },
                    {
                        label: 'Produkte',
                        fields: [
                            {
                                id: 'product_1',
                                type: 'pim',
                                extraColumns: 3,
                                recommendedCount: 20,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const BildZeichnung: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_BildZeichnung.xat',
        },
        fields: [
            Seitentitel,
            ImageHersteller,
            {
                type: 'image',
                id: 'image_product_1_1',
                label: 'Hauptbild',
                imageType: 'image_editable',
            },
        ],
    },
];


export const Vollbild: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Lagerliste_Vollbild.xat',
        },
        fields: [
            Seitentitel,
            ImageHersteller,
            {
                type: 'image',
                id: 'image_product_1_1',
                label: 'Vollbild',
                imageType: 'image_editable',
            },
        ],
    },
];




