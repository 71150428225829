import IBLSnippet, { IBLControlRichText, IBLControlText, IBLSnippetPage } from '../components/forms/BLForms/formInterfaces';
import { DreiProdukte, FuenfProdukte, SechsProdukte, VierProdukte, ZweiProdukte } from './werkzeugheftGCXAT';

const pageTitle: IBLSnippetPage = {
    name: 'Title',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Werkzeugheft_Cover.xat',
        },
        {
            type: 'headline',
            label: 'Kopfdaten',
        },
        {
            id: 'subline1',
            type: 'text',
            value: 'X. Ausgabe',
            label: 'Ausgabe',
        },
        {
            id: 'subline2',
            type: 'text',
            label: 'Zeitraum',
            placeholder: 'XX.XX.XXXX - XX.XX.XXXX',
        },
        {
            id: 'footer_datum',
            type: 'text',
            label: 'Gültigkeit bis',
            placeholder: 'XX.XX.XXXX',
        },
        {
            type: 'spacer',
            label: 'Titelbild',
        },
        {
            type: 'headline',
            label: 'Titelbild',
        },
        {
            id: 'image_cover',
            type: 'image',
            imageType: 'image_editable',
        },
    ],
};

const pageWelcome: IBLSnippetPage = {
    name: 'Begrüßung',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Werkzeugheft_S2.xat',
        },
        {
            type: 'headline',
            label: 'Begrüßung & Einführung',
        },
        {
            type: 'alert',
            text: 'Im folgenden Bereich wird die Begrüßung und Einführung in den Katalog abgehandelt.',
        },
        {
            type: 'tab',
            tabs: [
                {
                    label: 'Produkt 1',
                    fields: [
                        {
                            id: 'icon_product_1_1',
                            type: 'select',
                            label: 'Icon 1',
                            value: '',
                            items: {
                                '': 'leer',
                                'icons_gc_neu.pdf': 'Neuheit',
                                'icons_gc_preis.pdf': 'Preis-Hammer',
                                'icons_gc_gc24.pdf': 'GC24-Express',
                                'icons_gc_loesung.pdf': 'Lösungsfinder',
                                'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                            },
                        },
                        {
                            id: 'icon_product_1_2',
                            type: 'select',
                            label: 'Icon 2',
                            value: '',
                            items: {
                                '': 'leer',
                                'icons_gc_neu.pdf': 'Neuheit',
                                'icons_gc_preis.pdf': 'Preis-Hammer',
                                'icons_gc_gc24.pdf': 'GC24-Express',
                                'icons_gc_loesung.pdf': 'Lösungsfinder',
                                'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                            },
                        },
                        {
                            id: 'image_product_1_1',
                            type: 'image',
                            label: 'Produktbild',
                            imageType: 'image_editable',
                        },
                        {
                            id: 'preis_product_1',
                            type: 'text',
                            label: 'Preis',
                            placeholder: '0.000,00 €',
                            value: '',
                        },
                        {
                            id: 'preis_product_1_ab',
                            type: 'toggle',
                            label: 'Preis',
                            value: '',
                            valueTrue: '1',
                            valueFalse: '',
                        },
                        {
                            id: 'kbn_product_1',
                            type: 'text',
                            label: 'KBN',
                            placeholder: 'KBN',
                            value: '',
                        },
                        {
                            id: 'image_logo_1',
                            type: 'image',
                            label: 'Logo',
                            imageType: 'image_static',
                        },
                        {
                            id: 'headline_product_1',
                            type: 'text',
                            label: 'Headline',
                            placeholder: '',
                            value: '',
                        },
                        {
                            id: 'table_product_1',
                            type: 'WerkzeugheftTableBuilder',
                            label: 'TABELLE',
                            placeholder: '',
                            value: '',
                        },
                        {
                            id: 'table_product_1_Bezeichnung_1',
                            type: 'text',
                            label: 'Tabelle: Bezeichnung 1',
                            placeholder: 'Bezeichnung',
                            value: '',
                        },
                        {
                            id: 'table_product_1_KBN_1',
                            type: 'text',
                            label: 'Tabelle: KBN 1',
                            placeholder: 'XXXXXXXXXX',
                            value: '',
                        },
                        {
                            id: 'table_product_1_Preis_1',
                            type: 'text',
                            label: 'Tabelle: Preis 1',
                            placeholder: '000,00 €',
                            value: '',
                        },
                        {
                            id: 'table_product_1',
                            type: 'WerkzeugheftTableBuilder',
                            label: 'TABELLE',
                            placeholder: '',
                            value: '',
                        },
                        {
                            id: 'table_product_1_Bezeichnung_2',
                            type: 'text',
                            label: 'Tabelle: Bezeichnung 2',
                            placeholder: 'Bezeichnung',
                            value: '',
                        } as IBLControlText,
                        {
                            id: 'table_product_1_KBN_2',
                            type: 'text',
                            label: 'Tabelle: KBN 2',
                            placeholder: 'XXXXXXXXXX',
                            value: '',
                        } as IBLControlText,
                        {
                            id: 'table_product_1_Preis_2',
                            type: 'text',
                            label: 'Tabelle: Preis 2',
                            placeholder: '000,00 €',
                            value: '',
                        } as IBLControlText,
                        {
                            id: 'headline_product_1_RichText',
                            type: 'richtext',
                            label: 'Beschreibung',
                            bold: true,
                            listBulleted: true,
                        } as IBLControlRichText,
                    ],
                },
                {
                    label: 'Produkt 2',
                    fields: [
                        {
                            id: 'icon_product_2_1',
                            type: 'select',
                            label: 'Icon 1',
                            value: '',
                            items: {
                                '': 'leer',
                                'icons_gc_neu.pdf': 'Neuheit',
                                'icons_gc_preis.pdf': 'Preis-Hammer',
                                'icons_gc_gc24.pdf': 'GC24-Express',
                                'icons_gc_loesung.pdf': 'Lösungsfinder',
                                'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                            },
                        },
                        {
                            id: 'icon_product_2_2',
                            type: 'select',
                            label: 'Icon 2',
                            value: '',
                            items: {
                                '': 'leer',
                                'icons_gc_neu.pdf': 'Neuheit',
                                'icons_gc_preis.pdf': 'Preis-Hammer',
                                'icons_gc_gc24.pdf': 'GC24-Express',
                                'icons_gc_loesung.pdf': 'Lösungsfinder',
                                'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                            },
                        },
                        {
                            id: 'image_product_2_1',
                            type: 'image',
                            label: 'Produktbild',
                            imageType: 'image_editable',
                        },
                        {
                            id: 'preis_product_2',
                            type: 'text',
                            label: 'Preis',
                            placeholder: '0.000,00 €',
                            value: '',
                        },
                        {
                            id: 'preis_product_2_ab',
                            type: 'toggle',
                            label: 'Preis',
                            value: '',
                            valueTrue: '1',
                            valueFalse: '',
                        },
                        {
                            id: 'kbn_product_2',
                            type: 'text',
                            label: 'KBN',
                            placeholder: 'KBN',
                            value: '',
                        },
                        {
                            id: 'table_product_2',
                            type: 'text',
                            label: 'TABELLE TODO',
                            placeholder: '',
                            value: '',
                        },
                        {
                            id: 'table_product_2_Bezeichnung_1',
                            type: 'text',
                            label: 'Tabelle: Bezeichnung 1',
                            placeholder: 'Bezeichnung',
                            value: '',
                        },
                        {
                            id: 'table_product_2_KBN_1',
                            type: 'text',
                            label: 'Tabelle: KBN 1',
                            placeholder: 'XXXXXXXXXX',
                            value: '',
                        },
                        {
                            id: 'table_product_2_Preis_1',
                            type: 'text',
                            label: 'Tabelle: Preis 1',
                            placeholder: '000,00 €',
                            value: '',
                        },
                        {
                            id: 'table_product_2',
                            type: 'WerkzeugheftTableBuilder',
                            label: 'TABELLE',
                            placeholder: '',
                            value: '',
                        },
                        {
                            id: 'table_product_2_Bezeichnung_2',
                            type: 'text',
                            label: 'Tabelle: Bezeichnung 2',
                            placeholder: 'Bezeichnung',
                            value: '',
                        },
                        {
                            id: 'table_product_2_KBN_2',
                            type: 'text',
                            label: 'Tabelle: KBN 2',
                            placeholder: 'XXXXXXXXXX',
                            value: '',
                        },
                        {
                            id: 'table_product_2_Preis_2',
                            type: 'text',
                            label: 'Tabelle: Preis 2',
                            placeholder: '000,00 €',
                            value: '',
                        },
                        {
                            id: 'image_logo_2',
                            type: 'image',
                            label: 'Logo',
                            imageType: 'image_static',
                        },
                        {
                            id: 'headline_product_2',
                            type: 'text',
                            label: 'Headline',
                        },
                        {
                            id: 'headline_product_2_RichText',
                            type: 'richtext',
                            label: 'Beschreibung',
                            bold: true,
                            listBulleted: true,
                        } as IBLControlRichText,
                    ],
                },
            ],
        },
    ],
};

const lastPage: IBLSnippetPage = {
    name: 'Rückseite',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Werkzeugheft_BackCover.xat',
        },
        {
            type: 'headline',
            label: 'Rückseite',
        },
        {
            id: 'kbn_backcover',
            type: 'text',
            label: 'KBN',
        },
    ],
};

const dynamicPage: IBLSnippetPage = {
    name: 'Dynamische Seite',
    fields: [
        {
            id: 'template_layout',
            type: 'select',
            label: 'Layout wählen',
            autoUpdate: true,
            value: 'GC_Werkzeugheft_4_Produkte.xat',
            items: {
                'GC_Werkzeugheft_2_Produkte.xat': '2 Produkte',
                'GC_Werkzeugheft_3_Produkte.xat': '3 Produkte',
                'GC_Werkzeugheft_4_Produkte.xat': '4 Produkte',
                'GC_Werkzeugheft_5_Produkte.xat': '5 Produkte',
                'GC_Werkzeugheft_6_Produkte.xat': '6 Produkte',
            },
        },
        {
            type: 'spacer',
        },
        ...ZweiProdukte,
        ...DreiProdukte,
        ...VierProdukte,
        ...FuenfProdukte,
        ...SechsProdukte,
    ],
};

const werkzeugheftGC: IBLSnippet = {
    settings: {
        basePages: 8,
        pageAspectRatio: 0.707070707,
        insertAfter: 5,
        insertBeforeLast: 3,
    },
    staticVault: {
        mode: 'booklet',
    },
    pages: {
        1: pageTitle,
        2: pageWelcome,
        // Note: Dynamic pages here
        8: lastPage,
    },
    dynamicPage,
};

export default werkzeugheftGC;
