import { IBLControlRichText, TBLElements } from '../components/forms/BLForms/formInterfaces';

function createProduct(id: number): TBLElements {
    return {
        label: `Produkt ${id}`,
        fields: [
            {
                id: `icon_product_${id}_1`,
                type: 'select',
                label: 'Icon 1',
                items: {
                    '': 'leer',
                    'icons_gc_neu.pdf': 'Neuheit',
                    'icons_gc_preis.pdf': 'Preis-Hammer',
                    'icons_gc_gc24.pdf': 'GC24-Express',
                    'icons_gc_loesung.pdf': 'Lösungsfinder',
                    'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                },
            },
            {
                id: `icon_product_${id}_2`,
                type: 'select',
                label: 'Icon 2',
                items: {
                    '': 'leer',
                    'icons_gc_neu.pdf': 'Neuheit',
                    'icons_gc_preis.pdf': 'Preis-Hammer',
                    'icons_gc_gc24.pdf': 'GC24-Express',
                    'icons_gc_loesung.pdf': 'Lösungsfinder',
                    'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                },
            },
            {
                id: `image_product_${id}_1`,
                type: 'image',
                label: 'Produktbild',
                imageType: 'image_editable',
            },
            {
                id: `preis_product_${id}`,
                type: 'text',
                label: 'Preis',
                placeholder: '0.000,00 €',
            },
            {
                id: `preis_product_${id}_ab`,
                type: 'toggle',
                label: 'Preis',
                valueTrue: '1',
                valueFalse: '',
            },
            {
                id: `kbn_product_${id}`,
                type: 'text',
                label: 'KBN',
                placeholder: 'KBN',
            },
            {
                id: `image_logo_${id}`,
                type: 'image',
                label: 'Logo',
                imageType: 'image_static',
            },
            {
                id: `headline_product_${id}`,
                type: 'text',
                label: 'Headline',
            },
            {
                id: `headline_product_${id}_RichText`,
                type: 'richtext',
                label: 'Beschreibung',
                bold: true,
                listBulleted: true,
            } as IBLControlRichText,
        ],
    };
}

function createProductWithTable(id: number): TBLElements {
    return {
        label: `Produkt ${id}`,
        fields: [
            {
                id: `icon_product_${id}_1`,
                type: 'select',
                label: 'Icon 1',
                value: '',
                items: {
                    '': 'leer',
                    'icons_gc_neu.pdf': 'Neuheit',
                    'icons_gc_preis.pdf': 'Preis-Hammer',
                    'icons_gc_gc24.pdf': 'GC24-Express',
                    'icons_gc_loesung.pdf': 'Lösungsfinder',
                    'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                },
            },
            {
                id: `icon_product_${id}_2`,
                type: 'select',
                label: 'Icon 2',
                value: '',
                items: {
                    '': 'leer',
                    'icons_gc_neu.pdf': 'Neuheit',
                    'icons_gc_preis.pdf': 'Preis-Hammer',
                    'icons_gc_gc24.pdf': 'GC24-Express',
                    'icons_gc_loesung.pdf': 'Lösungsfinder',
                    'icons_gc_mehrwert.pdf': 'Mehrwert von GC',
                },
            },
            {
                id: `image_product_${id}_1`,
                type: 'image',
                label: 'Produktbild',
                imageType: 'image_editable',
            },
            {
                id: `preis_product_${id}`,
                type: 'text',
                label: 'Preis',
                placeholder: '0.000,00 €',
                value: '',
            },
            {
                id: `preis_product_${id}_ab`,
                type: 'toggle',
                label: 'Preis',
                value: '',
                valueTrue: '1',
                valueFalse: '',
            },
            {
                id: `kbn_product_${id}`,
                type: 'text',
                label: 'KBN',
                placeholder: 'KBN',
                value: '',
            },
            {
                id: `image_logo_${id}`,
                type: 'image',
                label: 'Logo',
                imageType: 'image_static',
            },
            {
                id: `headline_product_${id}`,
                type: 'text',
                label: 'Headline',
                placeholder: '',
                value: '',
            },
            {
                id: `table_product_${id}`,
                type: 'WerkzeugheftTableBuilder',
                label: 'TABELLE',
                placeholder: '',
                value: '',
            },
            {
                id: `table_product_${id}_Bezeichnung_1`,
                type: 'text',
                label: 'Tabelle: Bezeichnung 1',
                placeholder: 'Bezeichnung',
                value: '',
            },
            {
                id: `table_product_${id}_KBN_1`,
                type: 'text',
                label: 'Tabelle: KBN 1',
                placeholder: 'XXXXXXXXXX',
                value: '',
            },
            {
                id: `table_product_${id}_Preis_1`,
                type: 'text',
                label: 'Tabelle: Preis 1',
                placeholder: '000,00 €',
                value: '',
            },
            {
                id: `table_product_${id}`,
                type: 'WerkzeugheftTableBuilder',
                label: 'TABELLE',
                placeholder: '',
                value: '',
            },
            {
                id: `table_product_${id}_Bezeichnung_2`,
                type: 'text',
                label: 'Tabelle: Bezeichnung 2',
                placeholder: 'Bezeichnung',
                value: '',
            },
            {
                id: `table_product_${id}_KBN_2`,
                type: 'text',
                label: 'Tabelle: KBN 2',
                placeholder: 'XXXXXXXXXX',
                value: '',
            },
            {
                id: `table_product_${id}_Preis_2`,
                type: 'text',
                label: 'Tabelle: Preis 2',
                placeholder: '000,00 €',
                value: '',
            },
            {
                id: `headline_product_${id}_RichText`,
                type: 'richtext',
                label: 'Beschreibung',
                bold: true,
                listBulleted: true,
            } as IBLControlRichText,
        ],
    };
}

export const ZweiProdukte: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Werkzeugheft_2_Produkte.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    createProductWithTable(1),
                    createProductWithTable(2),
                ],
            },
        ],
    },
];

export const DreiProdukte: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Werkzeugheft_3_Produkte.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    createProduct(1),
                    createProduct(2),
                    createProductWithTable(3),
                ],
            },
        ],
    },
];

export const VierProdukte: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Werkzeugheft_4_Produkte.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    createProduct(1),
                    createProduct(2),
                    createProduct(3),
                    createProduct(4),
                ],
            },
        ],
    },
];

export const FuenfProdukte: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Werkzeugheft_5_Produkte.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    createProduct(1),
                    createProduct(2),
                    createProduct(3),
                    createProduct(4),
                    createProduct(5),
                ],
            },
        ],
    },
];

export const SechsProdukte: TBLElements[] = [
    {
        type: 'container',
        showWhen: {
            template_layout: 'GC_Werkzeugheft_6_Produkte.xat',
        },
        fields: [
            {
                type: 'tab',
                tabs: [
                    createProduct(1),
                    createProduct(2),
                    createProduct(3),
                    createProduct(4),
                    createProduct(5),
                    createProduct(6),
                ],
            },
        ],
    },
];
