import createStateContext from 'react-use/lib/factory/createStateContext';
import { IBLVaultGlobal } from './BLVault';
import { ICustomData } from 'src/pages/api/update/initial';

/**
 * name and value types of all fields used in form filling
 */
export interface Fields {
    id: string;
    vault: IBLVaultGlobal;
    pages: number;
    preflightDone: boolean;
    customData: ICustomData;
}

export const DefaultFieldsValues: Fields = {
    id: 'default',
    vault: {
        global: {},
        custom: {},
    },
    pages: 8, // TODO static for testing,
    preflightDone: false,
    customData: {},
};

/**
 * Main FormFilling context.
 * Creates a shared state context that you can use with `useField` hook to R/W to individual fields
 */
export const [useBLFormContext, BLFormContextProvider] = createStateContext<Fields>(DefaultFieldsValues);
