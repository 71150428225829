import IBLSnippet, { IBLSnippetPage } from '../components/forms/BLForms/formInterfaces';

const pageTitle: IBLSnippetPage = {
    name: 'Title',
    fields: [
        {
            type: 'alert',
            label: 'Keine Bearbeitungsoptionen',
            severity: 'info',
            text: 'Diese Produkt hat keine Optionen zur Personalisierung',
        },
    ],
};

const snippetEmpty: IBLSnippet = {
    settings: {
        basePages: 1,
        insertAfter: 1,
        insertBeforeLast: 8,
        pageAspectRatio: 0.70707,
    },
    staticVault: {
        mode: 'booklet',
    },
    pages: {
        1: pageTitle,
    },
};

export default snippetEmpty;
