import React, { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FormFillingContextProvider } from 'src/context/FormFillingContext';
import is from '@sindresorhus/is';
import snippetLoader from '../../snippets/snippetLoader';
import IBLSnippet from '../../components/forms/BLForms/formInterfaces';
import { BLFormContextProvider } from 'src/components/forms/BLForms/BLFormContext';

const Editor = React.lazy(() => import('../../components/organisms/Editor/Editor'));

export interface IHomeProps {
    documentId: string;
    productCode: string;
    apikey: string;
}

const Index: FunctionComponent<IHomeProps> = () => {
    const router = useRouter();

    const { documentId, productcode } = router.query;

    const [snippetName, setSnippetName] = useState<IBLSnippet | null>(null);

    useEffect(() => {
        if (!is.undefined(productcode)) {
            setSnippetName(snippetLoader(String(productcode)));
        }
    }, [productcode]);

    return (
        <>
            {snippetName !== null && (
                <FormFillingContextProvider>
                    <BLFormContextProvider>
                        <Suspense fallback={<div>Lade Daten...</div>}>
                            <Editor documentId={String(documentId) ?? 'dev'} snippet={snippetName} />
                        </Suspense>{' '}
                    </BLFormContextProvider>
                </FormFillingContextProvider>
            )}
        </>
    );
};

export default Index;
