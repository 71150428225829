import IBLSnippet, { IBLSnippetPage } from 'src/components/forms/BLForms/formInterfaces';
import {
    BildZeichnung,
    DreiProdukteTabelleText,
    EinProduktEinBildTabelleText,
    EinProduktZweiBilderTabelleText,
    FuenfProdukteTabelleText,
    SechsProdukteTabelle,
    VierProdukteTabelle,
    VierProdukteTabelleText,
    VierzigProdukteInTabelle,
    Vollbild,
    ZwanzigProdukteBilderInTabelle,
    ZweiProdukteTabelleText,
} from './lagerlisteGCXAT';

const pageTitle: IBLSnippetPage = {
    name: 'Titelseite',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Lagerliste_Cover.xat',
        },
        {
            type: 'headline',
            label: 'Kopfdaten',
        },
        {
            type: 'alert',
            label: 'Bildelement fehlt',
            severity: 'info',
            text:
                'Im folgenden Bereich finden Sie die Kopfdaten zu diesem Katalog. ' +
                'Einige Daten werden vom System aus eingespielt und können nicht geändert werden (grau hinterlegt).',
        },
        {
            id: 'headline1',
            type: 'text',
            readonly: true,
            defaultValue: '[data:CompanynameLegal]',
            label: 'Firmierung',
        },
        {
            id: 'subline1',
            type: 'text',
            readonly: true,
            defaultValue: '[data:CompanynameLegalAddon]',
            label: 'Untertitel Firmierung',
        },
        {
            id: 'headline2',
            type: 'text',
            label: 'Bezeichnung Headline',
            placeholder: 'Headline hinterlegen',
        },
        {
            id: 'subline2',
            type: 'text',
            label: 'Bezeichnung Subline',
            placeholder: 'Subline hinterlegen',
        },
        {
            id: 'year',
            type: 'text',
            label: 'Jahreszahl',
            placeholder: 'Jahreszahl hinterlegen',
            inputType: 'number',
        },
        {
            type: 'headline',
            label: 'Produkt Daten Upload (PIM)',
        },
        {
            type: 'pimupload',
        },
        {
            type: 'spacer',
            label: 'Titelbild',
        },
        {
            type: 'headline',
            label: 'Titelbild',
        },
        {
            id: 'image_cover',
            type: 'image',
            imageType: 'image_editable',
        },
    ],
};

const pageWelcome: IBLSnippetPage = {
    name: 'Begrüßung',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Lagerliste_Vorwort.xat',
        },
        {
            type: 'headline',
            label: 'Begrüßung & Einführung',
        },
        {
            type: 'alert',
            text: 'Im folgenden Bereich wird die Begrüßung und Einführung in den Katalog abgehandelt.',
        },
        {
            type: 'tab',
            tabs: [
                {
                    label: 'Anschreiben',
                    fields: [
                        {
                            id: 'vorwort_headline',
                            type: 'text',
                            label: 'Kunden Anrede',
                            placeholder: 'z.B. Lieber Kunde,',
                            value: 'Lieber Kunde,',
                            uppercase: true,
                        },
                        {
                            id: 'vorwort_copy',
                            type: 'text',
                            label: 'Einleitung',
                            placeholder: 'Einen Text als Einleitung',
                            multiline: true,
                            rows: 20,
                            // TODO Blindtext Generator
                            value: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna. Sed consequat, leo eget bibendum sodales, augue velit cursus nunc. \n\nIhr Name Mitarbeiter',
                        },
                    ],
                },
                {
                    label: 'Mitarbeiter',
                    fields: [
                        {
                            type: 'headline',
                            label: 'Person',
                        },
                        {
                            id: 'image_portrait',
                            type: 'image',
                            label: 'Portraitbild',
                            imageType: 'image_editable',
                        },
                        {
                            id: 'user_lastname',
                            type: 'text',
                            label: 'Nachname',
                            placeholder: '',
                            value: 'NAME',
                            uppercase: true,
                        },
                        {
                            id: 'user_firstname',
                            type: 'text',
                            label: 'Vorname',
                            placeholder: '',
                            value: 'VORNAME',
                            uppercase: true,
                        },
                        {
                            type: 'headline',
                            label: 'Untertitel',
                        },
                        {
                            id: 'fachabteilung_gross',
                            type: 'text',
                            label: 'Fachabteilung',
                            placeholder: '',
                            value: 'Fachabteilung',
                        },
                        {
                            id: 'fachabteilung_klein',
                            type: 'text',
                            label: 'Unterzeile',
                            placeholder: 'Beispiel: Haustechnik',
                            value: 'Beispiel: Haustechnik',
                            uppercase: true,
                        },
                    ],
                },
            ],
        },
    ],
};

const indexPage: IBLSnippetPage = {
    name: 'Inhaltsverzeichnis',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Lagerliste_Inhaltsverzeichnis.xat',
        },
        {
            type: 'headline',
            label: 'Inhaltsverzeichnis',
        },
    ],
};

const notesPages: IBLSnippetPage = {
    name: 'Notizen',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Lagerliste_Notizen.xat',
        },
        {
            type: 'headline',
            label: 'Notizen',
        },
        {
            type: 'alert',
            text: 'Diese Seite stellt keine Möglichkeiten der Personalisierung zur Verfügung',
        },
    ],
};

const lastPage: IBLSnippetPage = {
    name: 'Rückseite',
    fields: [
        {
            type: 'staticvalue',
            id: 'template_layout',
            value: 'GC_Lagerliste_BackCover.xat',
        },
        {
            type: 'headline',
            label: 'Rückseite',
        },
        {
            id: 'abbinder_firmierung',
            type: 'text',
            defaultValue: '[data:CompanynameLegal]',
            label: 'Firmierung',
            placeholder: 'Firmierung hinterlegen',
        },
        {
            id: 'abbinder_fachabteilung',
            type: 'text',
            label: 'Fachabteilung',
            placeholder: 'Fachabteilung hinterlegen',
        },
        {
            id: 'abbinder_strasse',
            type: 'text',
            defaultValue: '[data:Companystreet] [data:CompanystreetNumber]',
            label: 'Straße Hausnummer',
            placeholder: 'Straße Hausnummer hinterlegen',
        },
        {
            id: 'abbinder_plz_ort',
            type: 'text',
            defaultValue: '[data:CompanypostalCode] [data:Companycity]',
            label: 'PLZ Ort',
            placeholder: 'PLZ Ort hinterlegen',
        },
        {
            id: 'abbinder_telefon',
            type: 'text',
            defaultValue: '[data:telephone]',
            label: 'Telefon',
            placeholder: 'Telefon hinterlegen',
        },
        {
            id: 'abbinder_fax',
            type: 'text',
            defaultValue: '[data:fax]',
            label: 'Fax',
            placeholder: 'Fax hinterlegen',
        },
    ],
};

const dynamicPage: IBLSnippetPage = {
    name: 'Dynamische Seite',
    fields: [
        {
            id: 'template_layout',
            type: 'select',
            label: 'Layout wählen',
            autoUpdate: true,
            value: 'GC_Lagerliste_EinProduktEinBildTabelleText.xat',
            items: {
                '': 'Keins',
                'GC_Lagerliste_EinProduktEinBildTabelleText.xat': '1 Produkt, 1 Bild + Text',
                'GC_Lagerliste_EinProduktZweiBilderTabelleText.xat': '1 Produkt, 2 Bilder + Text',
                'GC_Lagerliste_ZweiProdukteTabelleText.xat': '2 Produkte + Text',
                'GC_Lagerliste_DreiProdukteTabelleText.xat': '3 Produkte + Text',
                'GC_Lagerliste_VierProdukteTabelleText.xat': '4 Produkte + Text',
                'GC_Lagerliste_FuenfProdukteTabelleText.xat': '5 Produkte + Text',
                'GC_Lagerliste_VierProdukteTabelle.xat': '4 Produkte',
                'GC_Lagerliste_SechsProdukteTabelle.xat': '6 Produkte',
                'GC_Lagerliste_VierzigProdukteInTabelle.xat': '40 Produkte',
                'GC_Lagerliste_ZwanzigProdukteBilderInTabelle.xat': '20 Produkte mit Bild',
                'GC_Lagerliste_BildZeichnung.xat': 'Bild / Zeichnung',
                'GC_Lagerliste_Vollbild.xat': 'Vollbild',
                'GC_Lagerliste_Notizen.xat': 'Notizseite',
            },
        },
        {
            type: 'spacer',
        },
        ...EinProduktEinBildTabelleText,
        ...EinProduktZweiBilderTabelleText,
        ...ZweiProdukteTabelleText,
        ...DreiProdukteTabelleText,
        ...VierProdukteTabelleText,
        ...FuenfProdukteTabelleText,
        ...VierProdukteTabelle,
        ...SechsProdukteTabelle,
        ...VierzigProdukteInTabelle,
        ...ZwanzigProdukteBilderInTabelle,
        ...BildZeichnung,
        ...Vollbild,
    ],
};

const snippetLagerlisteGC: IBLSnippet = {
    settings: {
        basePages: 8,
        pageAspectRatio: 0.707070707,
        insertAfter: 5,
        insertBeforeLast: 3,
    },
    staticVault: {
        mode: 'booklet', // linear
    },
    pages: {
        1: pageTitle,
        2: pageWelcome,
        3: indexPage,
        // Note: Dynamic pages here
        7: notesPages,
        8: lastPage,
    },
    dynamicPage,
};

export default snippetLagerlisteGC;
