import snippetLagerlisteGC from './lagerlisteGC';
import IBLSnippet from '../components/forms/BLForms/formInterfaces';
import emptySnippet from './emptySnippet';
import werkzeugheftGC from './werkzeugheftGC';

const snippetLoader = (name: string): IBLSnippet => {
    switch (name) {
        case '2021_GC_Lagerliste_PIM':
            return snippetLagerlisteGC;
        case '2021_GC_Werkzeugheft':
            return werkzeugheftGC;
        default:
            return emptySnippet;
    }
};

export default snippetLoader;
