import createStateContext from 'react-use/lib/factory/createStateContext';
import { Product } from 'src/lib/net/getPIMList';


export interface IProduct extends Product {
    discountGroup?: string;
    warehouse?: string;
}

export interface IUpdateScheduleItem {
    timeSent: string | null;
    fulfilled: boolean;
}

export type TPageCollection = number[][];

/**
 * name and value types of all fields used in form filling
 */
export interface IFormFillingFields {
    pageTitle: string;
    page: number;
    index: string;
    products: IProduct[];
    addColumnOne: string;
    addColumnTwo: string;
    priceColumn: boolean;
    headline: string;
    description: string;
    channelToken: string | null;
    pageState: string[];
    isUpdating: boolean;
    initialUpdate: boolean;
    isPopulated: boolean;
    documentId: string | null;
    pagesUpdating: number[];
    pageCollection: TPageCollection | null;
}

export const DefaultFieldsValues: IFormFillingFields = {
    pageTitle: '',
    page: 1,
    index: '',
    products: [],
    addColumnOne: '',
    addColumnTwo: '',
    priceColumn: false,
    headline: '',
    description: '',
    channelToken: null,
    pageState: [],
    isUpdating: false,
    initialUpdate: false,
    isPopulated: false,
    documentId: null,
    pagesUpdating: [],
    pageCollection: null,
};

/**
 * Main FormFilling context.
 * Creates a shared state context that you can use with `useField` hook to R/W to individual fields
 */
export const [useFormFillingContext, FormFillingContextProvider] =
    createStateContext<IFormFillingFields>(DefaultFieldsValues);
